<template>
  <section class="main-section" :class="{ 'manufacturer-section': isManufacturerComp }">
    <main-section-header :title="headerData[currentCompany].title" :subtitle="headerData[currentCompany].subtitle" />
    <div class="container">
      <list-utility-menu
        :hasSearchInput="true"
        :placeholder="headerData[currentCompany].placeholder"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage(1)"
        @keyupenter="onMoveSearchedPage(1)"
        :value="keyword"
      >
        <button-basic
          slot="button"
          color="blue"
          size="s"
          :width="113"
          :height="32"
          @click="onMoveEquipmentResister"
          v-if="isSupervisorOrFactory"
        >
          <plus-icon />
          등록
        </button-basic>
      </list-utility-menu>
      <list
        :list="equipmentList"
        :header-list="equipmentHeaderList"
        is-possible-hover-list="isPossibleHoverList"
        @sort="onClickSort"
      >
        <li
          slot="list-body"
          v-for="(equipment, index) in equipmentList"
          :key="index"
          @click="onMoveEquipmentDetail(equipment.id)"
        >
          <span>
            {{ { type: currentCompany, eqpId: equipment['id'] } | formatEQPCode }}
          </span>
          <span>{{ equipment.equipmentGroupName }}</span>
          <template v-if="isManufacturerComp">
            <span>{{ equipment.modelName }}</span>
            <span v-if="equipment.equipmentManagers">
              <span
                v-for="(equipmentManager, index) in equipment.equipmentManagers"
                :key="index"
                class="equipmentManagers"
              >
                {{ equipmentManager.userName }}
              </span>
            </span>
            <span v-else>-</span>
            <span>{{ equipment.factoryCompanyName }}</span>
            <span>{{ equipmentConfirmsStateName[equipment.equipmentConfirmsState] }}</span>
          </template>
          <template v-else>
            <span>{{ equipment.equipmentName }}</span>
            <span>
              <span v-if="equipment.factoryEquipmentManagers.length === 1">
                {{ getManagerByPriority(equipment.factoryEquipmentManagers, 1).userName }} / -
              </span>
              <span v-else>
                {{ getManagerByPriority(equipment.factoryEquipmentManagers, 1).userName }} /
                {{ getManagerByPriority(equipment.factoryEquipmentManagers, 2).userName }}
              </span>
            </span>
          </template>
          <span :class="{ 'color-red400': !equipment.isActive }">{{ equipment.isActive ? '활성' : '비활성' }}</span>
        </li>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </list>
      <pagination
        v-if="totalPageCount > 1"
        :page="page"
        :totalPageCount="totalPageCount"
        :pageSize="pageSize"
        @setPageNum="setPageNum"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import List from '@/component/list/List';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import ListHeaderData from './equipmentListData/ListHeaderData.json';
import ListUtilityMenu from '@/component/list/ListUtilityMenu.vue';
import Pagination from '@/component/pagination/Pagination';
import PlusIcon from 'vue-material-design-icons/Plus';
import noListDescription from '@/asset/js/noListDescription';
import getManagerName from '@/asset/js/getManagerName';
import HeaderData from './equipmentListData/HeaderData.json';

export default {
  name: 'EquipmentList',
  props: [],
  data() {
    return {
      headerData: HeaderData,
      equipmentList: null,
      equipmentHeaderList: ListHeaderData,
      page: 1,
      pageSize: 20,
      keyword: '',
      isRequestedDataWithKeyword: false,
      sortOrder: '',
      totalPageCount: 0,
      equipmentConfirmsStateName: {
        WAITING: '승인 대기',
        APPROVED: '승인',
        REJECTED: '반려',
      },
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isSupervisorOrFactory', 'isManufacturerComp']),
    noListDescription() {
      return noListDescription(this.isRequestedDataWithKeyword);
    },
  },
  created() {
    this.getEquipments();
  },
  mounted() {},
  watch: {
    $route() {
      this.getEquipments();
    },
  },
  methods: {
    ...mapActions('manufacturerEquipments', ['GET_MANUFACTURER_EQPS']),
    ...mapActions('factorySelfEquipments', ['GET_FACTORY_EQPS_SELF']),
    setQueryParams() {
      this.page = this.$route.query.page || 1;
      this.keyword = this.$route.query.keyword || '';
      this.sortOrder = this.$route.query.sortOrder || '';
    },
    getEquipments() {
      this.setQueryParams();
      this.equipmentList = null;
      this.isRequestedDataWithKeyword = !!this.keyword;
      this.totalPageCount = 0;
      switch (this.currentCompany) {
        case 'M':
          this.getManufacturerEquipments();
          break;
        case 'F':
          this.getFactoryEquipments();
          break;
      }
    },
    async getManufacturerEquipments() {
      const manufacturerEqps = await this.GET_MANUFACTURER_EQPS([
        this.page,
        this.pageSize,
        this.sortOrder,
        this.keyword,
      ]);
      this.equipmentList = manufacturerEqps.result;
      this.totalPageCount = manufacturerEqps['metaData'].totalPageCount;
    },
    async getFactoryEquipments() {
      const factoryEquipments = await this.GET_FACTORY_EQPS_SELF([
        this.page,
        this.pageSize,
        this.sortOrder,
        this.keyword,
      ]);
      this.equipmentList = factoryEquipments.result;
      this.totalPageCount = factoryEquipments['metaData'].totalPageCount;
    },
    onMoveEquipmentResister() {
      this.$router.push(`equipments/register`).catch((err) => err);
    },
    onMoveEquipmentDetail(eqpId) {
      this.$router.push(`equipments/${eqpId}`).catch((err) => err);
    },
    onMoveSearchedPage(page) {
      this.$router
        .push({
          query: {
            page: page || this.page || undefined,
            pageSize: this.pageSize || undefined,
            sortOrder: this.sortOrder || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    setPageNum(page) {
      this.page = page;
      this.onMoveSearchedPage();
    },
    onClickSort(sort) {
      if (this.sortOrder) {
        this.sortOrder = `${this.sortOrder.indexOf('-') === 0 ? '' : '-'}${sort}`;
      } else {
        this.sortOrder = sort;
      }
      this.onMoveSearchedPage();
    },
    getManagerByPriority(managers, priority) {
      return getManagerName(managers, priority);
    },
  },
  components: { MainSectionHeader, List, Pagination, ListUtilityMenu, PlusIcon },
};
</script>

<style scoped lang="scss">
@import 'EquipmentList';
</style>
